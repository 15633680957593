import React, { useState } from "react"
import Layout from "../../components/layout"
import Characters from "./data/characters"
import Character from "./components/Character"
//import Card from "../../components/card"

const getCharacters = () => {
    const characterList = Characters.map(character => {
        return <li key={character.key}><Character data={character}/></li>
    })
    return <ul>{characterList}</ul>
}

const index = () => {
  const [characters] = useState(getCharacters)
  return (
    <Layout>
      <div>{characters}</div>
      {/* <a href={Characters} download>characters</a> */}
    </Layout>
  )
}

export default index
