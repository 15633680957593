import React from 'react'

const Character = props => {
    const getData = () => {
        if (props.data) {
            const data = Object.entries(props.data)
            const traitList = []
            data.map(([trait, value]) => {
                if (trait !== "key" && trait !== "name") {
                    return traitList.push(<li key={trait}>{trait}: {value}</li>)
                }
                return null
            })
            return <ul>{traitList}</ul>
        }
        return <p></p>
    }
    const getName = () => {
        if (props.data) {
            return props.data.name
        }
        return ""
    }
    const characterData = getData()
    const characterName = getName()
    return (
        <div>
            <h2 style={{ marginBottom: 0 }} >{characterName}</h2>
            <div>{characterData}</div>
        </div>
    )
}

export default Character
